import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TACTIQ_DEFAULT_THEME } from "../utils/app.constants.utils";

export interface TactiqTheme {
  loginMainLogoUrl: string;
  loginFooterLogoUrl: string;
  loginFooterTemplate: string | SafeHtml;
  resetPasswordFooterTemplate: string | SafeHtml;
  loginBgImgUrl: string;
  loginBgColor: string;

  mainBgColor: string;
  mainTextColor: string;
  mainPrimaryColor: string;
}

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private config!: TactiqTheme;
  private http = inject(HttpClient);
  private sanitizer = inject(DomSanitizer);

  get configValue() {
    return this.config;
  }

  loadTheme(): void {
    this.config = TACTIQ_DEFAULT_THEME;
    this.config.loginFooterTemplate = this.sanitizer.bypassSecurityTrustHtml(
      this.config.loginFooterTemplate as string,
    );
    this.config.resetPasswordFooterTemplate =
      this.sanitizer.bypassSecurityTrustHtml(
        this.config.resetPasswordFooterTemplate as string,
      );

    const root = document.documentElement;
    root.style.setProperty("--tactiq-surface-ground", this.config.mainBgColor);
    root.style.setProperty("--tactiq-login-bg-color", this.config.loginBgColor);
    root.style.setProperty("--text-color", this.config.mainTextColor);
    root.style.setProperty(
      "--tactiq-primary-color",
      this.config.mainPrimaryColor,
    );
    // root.style.setProperty('--tactiq-sidebar-bg-color', config.sidebarBgColor);
    // root.style.setProperty('--tactiq-sidebar-bg-color-alt', config.sidebarBgColorAlt);
    // root.style.setProperty('--tactiq-sidebar-border', config.sidebarBorder);
    // root.style.setProperty('--tactiq-app-name-color', config.appNameColor);
    // root.style.setProperty('--tactiq-menu-separator-border', config.menuSeparatorBorder);
    // root.style.setProperty('--tactiq-menuitem-root-text-color', config.menuitemRootTextColor);
    // root.style.setProperty('--tactiq-menuitem-text-color', config.menuitemTextColor);
    // root.style.setProperty('--tactiq-menuitem-hover-bg', config.menuitemHoverBg);
    // root.style.setProperty('--tactiq-menuitem-active-bg', config.menuitemActiveBg);
    // root.style.setProperty('--tactiq-menuitem-text-active-color', config.menuitemTextActiveColor);
    // root.style.setProperty('--tactiq-menuitem-focus-shadow', config.menuitemFocusShadow);
  }
}
