export const routes = {
  api: {},
  frontend: {
    auth: "auth",

    login: "/auth/login",
    resetPassword: "/auth/reset-password",
    resetPasswordMfa: "/auth/reset-password-mfa",

    maintenance: "maintenance",
    users: "/maintenance/users",

    notfound: "notfound",
  },
};
